<template>
  <v-list-group
    v-for="facility in facilities"
    :key="facility"
    :value="facility.id + '-group'"
  >
    <template #activator="{ isOpen, props }">
      <v-list-item
        :value="facility.id"
        :title="facility.name"
        :data-cy="facility.name"
        append-icon=""
        v-bind="props"
        :active="isActive(facility)"
        @click.stop="updateValue(facility)"
      >
        <template #prepend>
          <v-icon
            v-if="!facility.children"
            @click.stop
          >
          </v-icon>
          <v-icon
            v-else-if="loading === facility.id"
            @click.stop
          >
            <v-progress-circular
              size="15"
              width="2"
              indeterminate
              data-cy="selectFacilityLoading"
            ></v-progress-circular>
          </v-icon>
          <v-icon
            v-else-if="isOpen"
            v-bind="props"
            data-cy="arrowDownButton"
            @click.stop
          >
            mdi-chevron-down
          </v-icon>
          <v-icon
            v-else
            v-bind="props"
            data-cy="arrowRightButton"
            @click.stop="$emit('open', facility)"
          >
            mdi-chevron-right
          </v-icon>
        </template>
        <template #append="{ isSelected, select }">
          <slot
            :facility="facility"
            name="append"
          >
          </slot>
          <v-list-item-action
            v-if="selectable"
            end
          >
            <v-checkbox-btn
              :disabled="filteredIds && filteredIds.includes(facility.id)"
              data-cy="facilityCheck"
              :model-value="isSelected"
              @click="
                !filteredIds || filteredIds.includes(facility.id) ? select(!isSelected) : null
              "
            ></v-checkbox-btn>
          </v-list-item-action>
        </template>
      </v-list-item>
    </template>

    <select-facility-list-groups
      :facilities="facility.children"
      :filtered-ids="filteredIds"
      :loading="loading"
      :model-value="modelValue"
      :selectable="selectable"
      :disable-parents="disableParents"
      :return-object="returnObject"
      @open="$emit('open', $event)"
      @update:model-value="$emit('update:modelValue', $event)"
    >
      <template #append="props">
        <slot
          v-bind="props"
          name="append"
        />
      </template>
    </select-facility-list-groups>
  </v-list-group>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: [String, Object],
      default: null,
    },
    facilities: {
      type: Array,
      default: () => [],
    },
    filteredIds: {
      type: Array,
      default: null,
    },
    loading: {
      type: String,
      default: null,
    },
    selectable: {
      type: Boolean,
    },
    disableParents: {
      type: Boolean
    },
    returnObject: {
      type: Boolean,
    },
  },
  emits: ['open', 'click', 'update:modelValue'],
  methods: {
    updateValue(facility) {
      this.$emit('open', facility)
      if ((!this.disableParents || !facility.children) && (!this.filteredIds || this.filteredIds.includes(facility.id))) {
        if (!this.selectable) {
          this.$emit('update:modelValue', this.returnObject ? facility : facility.id)
        }
      }
    },
    isActive(facility) {
      if (this.returnObject) {
        return this.modelValue && this.modelValue.id === facility.id
      } else {
        return this.modelValue === facility.id
      }
    },
  },
}
</script>
