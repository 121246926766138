<template>
  <div data-cy="selectFacility">
    <select-facility-list
      v-if="
        filteredIds && filteredIds.length < 20 && !currentUser.main_facility_admin && !selectable
      "
      :model-value="modelValue"
      :filter="facilityFilter"
      variant="outlined"
      label="Klinik auswählen"
      v-bind="{ ...$props, ...$attrs }"
      @update:model-value="updateModelValue"
    >
    </select-facility-list>
    <v-menu
      v-else
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      min-width="auto"
    >
      <template #activator="{ props }">
        <v-card
          variant="outlined"
          color="secondary"
          :style="{ paddingBottom: noPadding ? '0px !important' : null }"
        >
          <v-list-item
            height="52"
            class="no-margin text-black py-1"
            v-bind="{ ...props, ...$attrs }"
            border="none"
          >
            <v-list-item-subtitle class="text-truncate">
              {{ subtitle || label || 'Klinik auswählen' }}
            </v-list-item-subtitle>
            <v-list-item-title>{{ title }}</v-list-item-title>
            <template
              v-if="!readonly"
              #append
            >
              <v-icon>mdi-menu-down</v-icon>
            </template>
          </v-list-item>
        </v-card>
      </template>
      <v-card
        max-height="600"
        min-width="600"
        class="overflow-auto"
      >
        <v-list
          v-model:opened="open"
          v-model:selected="selectedIds"
          select-strategy="classic"
          data-cy="facilitiesList"
        >
          <select-facility-list-groups
            :model-value="modelValue"
            :facilities="facilities"
            :filtered-ids="filteredIds"
            :loading="childrenLoading"
            :selectable="selectable"
            :disable-parents="disableParents"
            :return-object="returnObject"
            @open="loadChildren"
            @update:model-value="updateModelValue"
          >
            <template #append="props">
              <slot
                v-bind="props"
                name="append"
              />
            </template>
          </select-facility-list-groups>
        </v-list>

        <!--v-treeview
          ref="tree"
          v-model="selection"
          v-model:active="active"
          dense
          hoverable
          readonly
          color="primary"
          :items="facilities"
          :load-children="loadChildren"
          :activatable="!selectable"
          :open="open"
          transition
          data-cy="facilityTree"
          :selectable="selectable"
          selected-color="primary"
          :return-object="returnObject"
        >
          <template #append="props">
            <slot
              v-bind="props"
              name="append"
            />
          </template>
        </v-treeview-->
      </v-card>
    </v-menu>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: [String, Object],
      default: null,
    },
    filteredIds: {
      type: Array,
      default: null,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    selectable: {
      type: Boolean,
    },
    facilityCardColor: {
      type: String,
      default: 'primary',
    },
    startOpen: {
      type: Boolean,
    },
    autoClose: {
      type: Boolean,
    },
    disableParents: {
      type: Boolean,
    },
    returnObject: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
    label: {
      type: String,
      default: 'Klinik auswählen',
    },
    chips: {
      type: Object,
      default: () => {},
    },
    noPadding: {
      type: Boolean,
    },
    excludeFacilityId: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue', 'update:selected'],
  data() {
    return {
      facilities: [],
      open: [],
      lastOpen: [],
      title: null,
      subtitle: null,
      menu: false,
      childrenLoading: null,
    }
  },
  computed: {
    facilityFilter() {
      return {
        id: {
          _in: this.filteredIds,
        },
      }
    },
    active: {
      get() {
        return [this.modelValue]
      },
      set(val) {
        if (val) {
          if (val[0] !== this.modelValue) {
            this.$emit('update:modelValue', val[0])
          }
        } else {
          this.$emit('update:modelValue', null)
        }
        this.menu = false
      },
    },
    selectedIds: {
      get() {
        return this.returnObject ? this.selected.map((facility) => facility.id) : this.selected
      },
      set(value) {
        let selected = value
        if (this.returnObject) {
          selected = value.map((facilityId) => {
            const facility = this.getFacility(facilityId)
            return facility ? facility : { id: facilityId }
          })
        }
        this.$emit('update:selected', selected)
      },
    },
  },
  watch: {
    modelValue: {
      handler(value) {
        this.setName()
        if (value && this.autoClose) {
          this.expansion = null
        }
      },
    },
    selected: {
      handler() {
        this.setName()
      },
    },
    open(val) {
      if (!val || !val.length) {
        this.open = this.lastOpen
      }
      this.lastOpen = this.open
    },
  },
  async mounted() {
    await this.loadData()
    //this.open = this.facilities.map((facility) => facility.id)
    this.facilities.forEach((facility) => this.loadChildren(facility))
    this.menu = this.startOpen
  },
  methods: {
    async loadData() {
      const facilities = await this.loadChildFacilities()
      this.facilities = facilities
      if (this.modelValue) {
        this.active = [this.modelValue]
      } else if (this.facilities.length === 1 && !this.facilities[0].children) {
        this.active = [this.facilities[0].id]
      }
      this.setName()
    },
    async setName() {
      if (this.modelValue) {
        let id = this.modelValue
        if (this.returnObject && typeof this.modelValue === 'object') {
          id = this.modelValue.id
        }
        await this.$cms
          .request(
            this.$readItem('facility', id, {
              fields: [
                'id',
                'name',
                'show_in_hierarchy',
                'parent_facility.id',
                'parent_facility.name',
                'parent_facility.show_in_hierarchy',
                'parent_facility.parent_facility.id',
                'parent_facility.parent_facility.name',
                'parent_facility.parent_facility.show_in_hierarchy',
                'parent_facility.parent_facility.parent_facility.id',
                'parent_facility.parent_facility.parent_facility.name',
                'parent_facility.parent_facility.parent_facility.show_in_hierarchy',
                'parent_facility.parent_facility.parent_facility.parent_facility.id',
                'parent_facility.parent_facility.parent_facility.parent_facility.name',
                'parent_facility.parent_facility.parent_facility.parent_facility.show_in_hierarchy',
                'parent_facility.parent_facility.parent_facility.parent_facility.parent_facility.id',
                'parent_facility.parent_facility.parent_facility.parent_facility.parent_facility.name',
                'parent_facility.parent_facility.parent_facility.parent_facility.parent_facility.show_in_hierarchy',
              ],
            })
          )
          .then((response) => {
            this.title = response.name
            this.subtitle = this.formatFacilityHierarchy(response)
          })
      } else if (this.selected.length) {
        const lengthText =
          (this.selected.length === 1 ? '1 Einrichtung' : this.selected.length + ' Einrichtungen') +
          ' ausgewählt'
        if (this.returnObject) {
          this.title = this.selected.map((f) => f.name).join(', ')
          this.subtitle = lengthText
        } else {
          this.title = lengthText
          this.subtitle = null
        }
      } else {
        this.title = null
        this.subtitle = null
      }
    },
    async loadChildren(item) {
      const children = await this.loadChildFacilities(item.id)
      if (children.length) {
        item.children = []
        item.children.push(...children)
      }
      return item
    },
    async loadChildFacilities(parentFacilityId) {
      this.childrenLoading = parentFacilityId
      const _and = [
        {
          name: {
            _nnull: true,
          },
        },
        {
          status: {
            _neq: 'ARCHIVED',
          },
        },
      ]
      if (parentFacilityId) {
        _and.push({
          parent_facility: {
            _eq: parentFacilityId,
          },
        })
      } else {
        _and.push({
          parent_facility: {
            _null: true,
          },
        })
      }
      return this.$cms
        .request(
          this.$readItems('facility', {
            filter: { _and },
            fields: ['id', 'name', 'parent_facility', 'child_facilities.id', ...this.fields],
            sort: ['name'],
          })
        )
        .then((response) => {
          response = this.excludeFacility(response)
          response.sort((a, b) => a.name.localeCompare(b.name))
          this.childrenLoading = null
          return response.map((facility) => {
            return {
              ...facility,
              children: facility.child_facilities.length ? [] : undefined,
            }
          })
        })
    },
    getFacility(facilityId, facilities = this.facilities) {
      for (const facility of facilities) {
        if (facility.id === facilityId) return facility
        if (facility.children) {
          const childFacility = this.getFacility(facilityId, facility.children)
          if (childFacility) return childFacility
        }
      }
    },
    updateModelValue(event) {
      this.$emit('update:modelValue', event)
      if (!this.selectable) {
        this.menu = false
      }
    },

    excludeFacility(facilities) {
      // If excludeFacilityId is provided, filter it out
      if (this.excludeFacilityId) {
        const filteredFacilities = facilities.filter(
          (facility) => facility.id !== this.excludeFacilityId
        )

        // Also filter out child facilities with excludeFacilityId
        return filteredFacilities.map((facility) => ({
          ...facility,
          child_facilities: facility.child_facilities.filter(
            (child) => child.id !== this.excludeFacilityId
          ),
        }))
      }

      // If excludeFacilityId is not provided, return original facilities
      return facilities
    },
  },
}
</script>

<style lang="scss">
.no-margin {
  margin: 0 !important;
}

.choose-facility-card {
  border-color: rgba(0, 0, 0, 0.42) !important;

  .v-expansion-panel-content__wrap {
    padding: 8px !important;
  }
  .v-expansion-panel-header {
    padding: 18px 12px !important;
  }
}
</style>
